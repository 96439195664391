<div class="container" *ngIf="formReadyToShow">
  <div class="col-md-12 my-4">
    <div class="card card-body bg-light">
      <form novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm"
            autocomplete="off">
        <fieldset>

          <app-formheader [formType]="formType" [formName]="formName" [id]="1"
                          [sts]="sts"></app-formheader>




          <app-formtextarea [inputMinLength]="'3'" [control]="huTextControl"
                            [inputName]="'hutext'" [title]="'systemmessage.hutext'" [sts]="sts"
                            [parentFormGroup]="mainForm" [permissions]="permissions"
                            [higherRoleIsNeeded]="higherRoleIsNeeded"
                            [readonly]="readonly"
                            [actionInProgress]="actionInProgress"></app-formtextarea>


          <app-formtextarea [inputMinLength]="'3'" [control]="enTextControl"
                            [inputName]="'entext'" [title]="'systemmessage.entext'" [sts]="sts"
                            [parentFormGroup]="mainForm" [permissions]="permissions"
                            [higherRoleIsNeeded]="higherRoleIsNeeded"
                            [readonly]="readonly"
                            [actionInProgress]="actionInProgress"></app-formtextarea>


          <app-formtextarea [inputMinLength]="'3'" [control]="deTextControl"
                            [inputName]="'detext'" [title]="'systemmessage.detext'" [sts]="sts"
                            [parentFormGroup]="mainForm" [permissions]="permissions"
                            [higherRoleIsNeeded]="higherRoleIsNeeded"
                            [readonly]="readonly"
                            [actionInProgress]="actionInProgress"></app-formtextarea>

          <app-formtextarea [inputMinLength]="'3'" [control]="deTextControl"
                            [inputName]="'pltext'" [title]="'systemmessage.pltext'" [sts]="sts"
                            [parentFormGroup]="mainForm" [permissions]="permissions"
                            [higherRoleIsNeeded]="higherRoleIsNeeded"
                            [readonly]="readonly"
                            [actionInProgress]="actionInProgress"></app-formtextarea>



          <app-formswitch [control]="activeControl" [inputName]="'active'"
                          [title]="'systemmessage.active'" [sts]="sts"
                          [parentFormGroup]="mainForm" [permissions]="permissions"
                          [higherRoleIsNeeded]="higherRoleIsNeeded"
                          [readonly]="readonly"
                          [actionInProgress]="actionInProgress">
          </app-formswitch>




          <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                           [fileUploadOptions]="fileUploadOptions"
                           [readonly]="readonly"
                           [showCancel]="false"
                           [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

        </fieldset>
      </form>
    </div>
  </div>
</div>

import { azul } from './azul';
import { beeInspired } from './bee-inspired';
import { blue } from './blue';
import { caravan } from './caravan';
import { carp } from './carp';
import { cool } from './cool';
import { dark } from './dark';
import { darkBlue } from './dark-blue';
import { darkBold } from './dark-bold';
import { darkDigerati } from './dark-digerati';
import { darkFreshCut } from './dark-fresh-cut';
import { darkMushroom } from './dark-mushroom';
import { eduardo } from './eduardo';
import { forest } from './forest';
import { freshCut } from './fresh-cut';
import { fruit } from './fruit';
import { gray } from './gray';
import { green } from './green';
import { helianthus } from './helianthus';
import { infographic } from './infographic';
import { inspired } from './inspired';
import { jazz } from './jazz';
import { london } from './london';
import { macarons } from './macarons';
import { macarons2 } from './macarons2';
import { mint } from './mint';
import { red } from './red';
import { redVelvet } from './red-velvet';
import { roma } from './roma';
import { royal } from './royal';
import { sakura } from './sakura';
import { shine } from './shine';
import { techBlue } from './tech-blue';
import { vintage } from './vintage';
import { hybridbooks } from './hybridbooks';
export const Azul = azul ;
export const BeeInspired = beeInspired ;
export const Blue = blue ;
export const Caravan = caravan ;
export const Carp = carp ;
export const Cool = cool ;
export const DarkBlue = darkBlue ;
export const DarkBold = darkBold ;
export const DarkDigerati = darkDigerati ;
export const DarkFreshCut = darkFreshCut ;
export const DarkMushroom = darkMushroom ;
export const Dark = dark ;
export const Eduardo = eduardo ;
export const Forest = forest ;
export const FreshCut= freshCut ;
export const Fruit = fruit ;
export const Gray = gray ;
export const Green = green ;
export const Helianthus = helianthus ;
export const Infographic = infographic ;
export const Inspired = inspired ;
export const Jazz = jazz ;
export const London = london ;
export const Macarons = macarons ;
export const Macarons2 = macarons2 ;
export const Mint = mint ;
export const RedVelvet = redVelvet ;
export const Red = red ;
export const Roma = roma ;
export const Royal = royal ;
export const Sakura = sakura ;
export const Shine = shine ;
export const TechBlue = techBlue ;
export const Vintage = vintage ;
export const Hybridbooks = hybridbooks ;

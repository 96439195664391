import { Component, OnDestroy, OnInit } from "@angular/core";
import { PermissionsService } from "../../services/permissions.service";
import { environment } from "../../../../environments/environment";
import { DashboardService } from "../../services/dashboard.service";
import { MessageService } from "../../services/message.service";
import { EChartsOption } from "echarts";
import { BookChartData } from "../../models/BookChartData";
import { Hybridbooks, Royal } from "./themes/themes";
import * as moment from "moment";
import { AdmingridService } from "../../services/admingrid.service";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public permissions;
  public tableName: string;
  public booksData: any;
  public readyToShow: boolean = false;
  public rootUrl = `${environment.backendApiUrl}${environment.backendApiVersion}`;
  public isSuperadmin =
    JSON.parse(sessionStorage.getItem("userroles")).indexOf("Superadmin") != -1;
  public st;
  private subsink = new SubSink();

  public viewsdata;
  public heatmapdata;
  private showHeatmapData: boolean;
  private bookChartData: BookChartData[] = [];

  private compareChartOptions: EChartsOption;
  private pieChartOptions: EChartsOption;
  private viewsChartOptions: EChartsOption;
  private calendarHeatmapOptions: EChartsOption;
  private fileViewOptions: EChartsOption;
  private popularBooksOptions: EChartsOption;

  public theme = Hybridbooks;
  public theme2 = Royal;
  private popularLimit;
  private selectedBooks: BookChartData[];
  private selectedBook: BookChartData;
  private appUsageData: any[];
  private books;
  private booksInfo:any[];
  private dateData = this.CreateDateData(2022, 2030);
  private selectedDate;

  dateSettings = {
    singleSelection: true,
    text: "Dátum kiválasztása",
    enableSearchFilter: false,
    enableCheckAll: false,
    badgeShowLimit: 5,
    labelKey: "year",
    enableFilterSelectAll: false,
  };
  dropdownSettings = {
    singleSelection: false,
    text: "Könyvek kiválasztása",
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 5,
    labelKey: "name",
    enableFilterSelectAll: false,
  };
  fileViewSettings = {
    singleSelection: true,
    text: "Könyv kiválasztása",
    enableSearchFilter: true,
    enableCheckAll: false,
    badgeShowLimit: 5,
    labelKey: "name",
    enableFilterSelectAll: false,
  };
  popularBooksSettings = {
    singleSelection: true,
    text: "Népszerűség kiválasztása",
    enableSearchFilter: false,
    enableCheckAll: false,
    badgeShowLimit: 5,
    labelKey: "name",
    enableFilterSelectAll: false,
  };
  constructor(
    private permissionsService: PermissionsService,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private admingridService: AdmingridService
  ) {}
  popularLimitData = [{id:0,name:'Top 5',value:5},{id:1,name:'Top 10',value:10},{id:2,name:'Top 20',value:20}];
  CreateDateData(from: number, to: number) {
    let dateData = [];
    let i = 0;
    for (let year = from; year <= to; year++) {
      const element = { id: i, year: year };
      dateData.push(element);
      i++;
    }
    return dateData;
  }

  OnDateSelect(ev) {
    this.GetHeatmap(ev);
  }

  GetHeatmap(date: any) {
    this.subsink.add(
      this.dashboardService.getHeatmap(date.year).subscribe((resp: any) => {
        var heatmapdata = this.GetHeatmapData(date.year, resp.data);
        this.CreateCalendarChart(date.year, heatmapdata.data, heatmapdata.max);
      })
    );
  }

  GetHeatmapData(year: number, appUsageData: any[]) {
    let date = moment(year + "-01-01").valueOf();
    let end = moment(year + 1 + "-01-01").valueOf();
    var dayTime = 3600 * 24 * 1000;
    var data: any[] = [];
    for (var time = date; time < end; time += dayTime) {
      let currentDate = moment(time).format("yyyy-MM-DD");
      let usageData = appUsageData.filter((x) => x[0] == currentDate);
      if (usageData.length == 0) {
        data.push([currentDate, 0]);
      } else {
        data.push(usageData[0]);
      }
    }
    let viewCountArray: number[] = appUsageData.map((x) => x[1]);
    viewCountArray = [...new Set(viewCountArray)];
    let maxVal = 0;
    viewCountArray.forEach((x) => {
      if (x > maxVal) {
        maxVal = x;
      }
    });
    return { data: data, max: maxVal };
  }

  CreateCalendarChart(year: number, data: any[], max: number) {
    this.showHeatmapData = false;
    var symbolSize = max / 20;
    let calendarChart: EChartsOption = {
      tooltip: {
        formatter: (params) => {
          return this.TooltipFormatter(params);
        },
      },
      visualMap: {
        min: 1,
        max: max,
        type: "piecewise",
        orient: "horizontal",
        left: "center",
        splitNumber: 5,
        top: 65,
        inRange: {
          color: ["#be9800", "#009000"], //From smaller to bigger value ->
        },
      },
      calendar: {
        top: 120,
        left: 30,
        right: 30,
        cellSize: ["auto", 20],
        range: year,
        dayLabel: {
          nameMap: ["V", "H", "K", "SZ", "CS", "P", "SZ"],
        },
        yearLabel: {
          show: true,
          color: "#fff",
        },
        itemStyle: {
          color: "#e7e7e7",
          borderWidth: 0.5,
          borderColor: "#2A3F54",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2A3F54",
            width: 2,
            type: "solid",
          },
        },
      },
      series: {
        name: "App használat",
        type: "scatter",
        coordinateSystem: "calendar",
        data: data,
        symbolSize: function (val) {
          return val[1] / symbolSize;
        },
      },
    };
    this.calendarHeatmapOptions = calendarChart;
    this.showHeatmapData = true;
  }

  ngOnInit() {
    this.tableName = "dashboard";
    this.permissions = this.permissionsService.getPermissions(this.tableName);
    this.GetPublications();
    //TODO SAVE TO LOCAL STORAGE
    this.selectedDate = [this.dateData[0]];
    this.GetHeatmap(this.dateData[0]);
    this.dashboardService.getBooksData().subscribe(
      (data) => {
        this.st = data.st;
        this.readyToShow = true;
      },
      (error) => {
        console.log(<any>error);
        var text =
          typeof error["error"] == "object" &&
          error.error.hasOwnProperty("message")
            ? error.error.message
            : error.message;
        this.messageService.sendMessage({ text: text, type: "danger" });
      }
    );
  }
  async GetPopular(){
    const books= await this.GetPopularBooks(2);
    const contents= await this.GetPopularContents(2);
    console.log(books);
    console.log(contents);
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  GetCompareChart(bookId: number) {
    return this.dashboardService.getCompareChart(bookId).toPromise();
  }

  GetPublications() {
    this.subsink.add(
      this.admingridService
        .getItems("books", 0, 0, [], "", {})
        .pipe(map((books) => books.items))
        .subscribe((b) => {
          this.booksInfo = b;
          let bookData: BookChartData[] = [];
          b.forEach((book) => {
            var bookChart: BookChartData = {
              id: book.id,
              name: book.name,
            };
            bookData.push(bookChart);
          });
          this.bookChartData = bookData;
          this.LoadSelectedBooks();
          this.popularLimit = [{id:0,name:'Top 5',value:5}];
          this.CreatePopularBooksChart(5);
        })
    );
  }

  async GetAllBooks(books: any[]) {
    const promiseArray = new Array(books.length);
    let i = 0;
    for await (const book of books) {
      const a = this.GetBook(book.id);
      promiseArray[i] = a;
      i++;
    }
    const files = await Promise.all(promiseArray);
    return files;
  }

  async GetBook(id: any) {
    return this.admingridService.getItem("books", id).pipe(map((data) => data.item)).toPromise();
  }

  GetPopularBooks(limit?:number){
    return this.dashboardService.getPopularBooks(limit).toPromise();
  }
  GetPopularContents(id:number){
    return this.dashboardService.getPopularContents(id).toPromise();
  }

  async CreateFileViewChart(id:number) {
    let titles:any[] = [];
    let values:any[] = [];
    const book = await this.GetBook(id);
    const arFiles = book.ar_files_grouped;
    const contents:any = await this.GetPopularContents(id);
    contents.data.forEach(content => {
      const file = arFiles.find(ar=>ar.uid == content.fc_uid);
      let title = file.title;
      titles.push(title);
      values.push(content.count);
    });
    titles.reverse();
    values.reverse();
    this.fileViewOptions = {
      title: {
        text: "Legnézettebb tartalmak",
        textStyle: {
          fontWeight: "lighter",
        },
        padding: [12, 8],
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: "category",
        data: titles,
      },
      series: [
        {
          type: "bar",
          data: values,
        },
      ],
    };
  }

  async CreatePopularBooksChart(limit:number) {
    let popBooks:any = await this.GetPopularBooks(limit);
    let popularBooks = popBooks.data;
    let names = [];
    let values = [];
    for (let i = popularBooks.length-1; i >= 0; i--) {
      const book = popularBooks[i];
      const id = parseInt(book[0]);
      let b = this.booksInfo.find(x=>x.id == id);
      const value = book[1];
      names.push(b.name);
      values.push(value);
    }
    this.popularBooksOptions = {
      title: {
        text: "Legnépszerűbb kiadványok",
        textStyle: {
          fontWeight: "lighter",
        },
        padding: [12, 8],
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: "category",
        data: names,
      },
      series: [
        {
          type: "bar",
          data: values,
        },
      ],
    };
  }

  CreatePieChart() {
    let pieChartData = [];
    this.selectedBooks.forEach((bookChart) => {
      let pieces = bookChart.data.bookRegistration.map((u) => u[1]);
      let total = 0;
      pieces.map((v) => (total += v));
      pieChartData.push({
        id: bookChart.id,
        name: bookChart.name,
        value: total,
      });
    });
    this.pieChartOptions = {
      title: {
        text: "Összes aktiválás",
        textStyle: {
          fontWeight: "lighter",
        },
        padding: [12, 8],
      },
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          name: "Összes aktiválás", ///Ide jön egy dinamikus text
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          labelLine: {
            length: 30,
          },
          label: {
            formatter: "{b|{b}}\n{hr|}\n{c|{c}} {per|{d}%}",
            backgroundColor: "#F6F8FC",
            borderColor: "#8C8D8E",
            padding: [0, 8, 0, 8],
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              c: {
                color: "#4C5058",
                fontSize: 12,
                align: "left",
                fontWeight: "bold",
                lineHeight: 24,
              },
              hr: {
                borderColor: "#8C8D8E",
                width: "100%",
                borderWidth: 1,
                height: 0,
              },
              b: {
                color: "#4C5058",
                fontSize: 14,
                lineHeight: 26,
              },
              per: {
                color: "#fff",
                backgroundColor: "#4C5058",
                align: "right",
                padding: [3, 4],
                borderRadius: 4,
              },
            },
          },
          data: pieChartData,
        },
      ],
    };
  }

  CreateViewsChart() {
    let legendData = [];
    let series = [];
    this.selectedBooks.forEach((bookChart) => {
      legendData.push(bookChart.name);
      let data = [];
      bookChart.data.views.forEach(view => {
        data.push(view);
      });
      series.push({
        data: data,
        name: bookChart.name,
        type: "bar",
        barMaxWidth: 10,
        barMinWidth: 4,
        animationDelay: (idx) => idx * 10,
      });
    });
    let chart: EChartsOption = {
      title: {
        text: "Könyv használat",
        textStyle: {
          fontWeight: "lighter",
        },
        padding: [12, 8],
      },
      legend: {
        data: legendData,
        orient: "horizontal",
        align: "left",
      },
      xAxis: {
        type: "time",
        axisLabel: {
          formatter: "{yyyy}-{MM}-{dd}",
        },
      },
      yAxis: {
        type: "value",
      },
      dataZoom: [
        {
          type: "slider",
          start: 80,
          end: 100,
        },
      ],
      tooltip: {
        formatter: (params) => {
          return this.TooltipFormatter(params);
        },
      },
      series: [],
      animationEasing: "elasticOut",
      animationDelayUpdate: (idx) => idx * 5,
    };
    chart.series = series;
    this.viewsChartOptions = chart;
  }

  CreateCompareChart() {
    let dates = [];
    let legendData = [];
    let series = [];
    this.selectedBooks.forEach((bookChart) => {
      dates = bookChart.data.bookRegistration.map((u) => u[0]);
      let pieces = bookChart.data.bookRegistration.map((u) => u[1]);
      legendData.push(bookChart.name);
      let data = [];
      for (let i = 0; i < dates.length; i++) {
        const date = dates[i];
        data.push([date, pieces[i]]);
      }
      series.push({
        data: data,
        name: bookChart.name,
        type: "bar",
        barMaxWidth: 10,
        barMinWidth: 4,
        animationDelay: (idx) => idx * 10,
      });
    });
    let chart: EChartsOption = {
      title: {
        text: "Könyvregisztráció",
        textStyle: {
          fontWeight: "lighter",
        },
        padding: [12, 8],
      },
      legend: {
        data: legendData,
        orient: "horizontal",
        align: "left",
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
      },
      dataZoom: [
        {
          type: "slider",
          start: 80,
          end: 100,
        },
      ],
      tooltip: {
        formatter: (params) => {
          return this.TooltipFormatter(params);
        },
      },
      series: [],
      animationEasing: "elasticOut",
      animationDelayUpdate: (idx) => idx * 5,
    };
    chart.series = series;
    this.compareChartOptions = chart;
  }

  TooltipFormatter(params?) {
    return (
      params.marker +
      params.seriesName +
      '<br><div style="font-size:16px;font-weight:bold">' +
      params.value[1] +
      "</div>" +
      params.value[0]
    );
  }


  OnBookSelect($event) {
    this.CreateFileViewChart($event.id);
  }

  OnBookDeSelect($event) {


  }

  OnPopularSelect($event) {
    console.log($event);
    this.CreatePopularBooksChart($event.value);
  }
  OnPopularDeSelect($event) {

  }
  //Multi Select functions

  OnItemSelect($event) {
    this.UpdateCharts();
  }
  OnItemSelectAll($event) {
    this.UpdateCharts();
  }
  OnItemDeSelect($event) {
    this.UpdateCharts();
  }
  OnDeSelectAll($event) {
    this.selectedBooks = [];
    this.UpdateCharts();
  }
  async UpdateCharts() {
    let selectedIds = this.selectedBooks.map((i) => i.id);
    this.selectedBooks = this.bookChartData.filter((d) =>
      selectedIds.includes(d.id)
    );
    for await (const item of this.selectedBooks) {
      let response: any = await this.GetCompareChart(item.id);
      item.data = response.data;
    }

    this.CreateCompareChart();
    this.CreatePieChart();
    this.CreateViewsChart();
    this.SaveSelectedBooks();
  }

  async LoadSelectedBooks() {
    let idsString = localStorage.getItem("dashboard_items");
    if (idsString == null) {
      return;
    }
    let ids = idsString.split(",").map((id) => parseInt(id));
    let books = [];
    for await (const book of this.bookChartData) {
      if (ids.includes(book.id)) {
        books.push(book);
      }
    }
    this.selectedBooks = books;
    this.UpdateCharts();
  }

  SaveSelectedBooks() {
    let ids = this.selectedBooks.map((i) => i.id);
    localStorage.setItem("dashboard_items", ids.toString());
  }
}

<div *ngIf="permissions.Show" class="container-fluid">
  <div class="row">
    <div class="col align-self-center">
      <section>
        <span class="section-title">Applikáció</span>
        <div class="year-selector">
          <angular2-multiselect
            [data]="dateData"
            [settings]="dateSettings"
            [(ngModel)]="selectedDate"
            (onSelect)="OnDateSelect($event)"
            (onDeSelect)="OnDateDeSelect($event)"
          >
          </angular2-multiselect>
        </div>
        <div *ngIf="showHeatmapData">
          <div
            echarts
            [options]="calendarHeatmapOptions"
            class="bar-chart"
          ></div>
        </div>
      </section>
      <section>
        <span class="section-title">Könyvek</span>

          <div class="m-1">
            <angular2-multiselect
              [data]="bookChartData"
              [settings]="dropdownSettings"
              [(ngModel)]="selectedBooks"
              (onSelect)="OnItemSelect($event)"
              (onDeSelect)="OnItemDeSelect($event)"
              (onSelectAll)="OnItemSelectAll($event)"
              (onDeSelectAll)="OnDeSelectAll($event)"
            >
            </angular2-multiselect>
          </div>

          <div *ngIf="selectedBooks?.length > 0">
            <div
              echarts
              [options]="compareChartOptions"
              [theme]="theme"
              class="bar-chart"
            ></div>
            <div>
              <div
                echarts
                [options]="pieChartOptions"
                [theme]="theme"
                class="pie-chart"
              ></div>
            </div>
            <div>
              <div
                echarts
                [options]="viewsChartOptions"
                [theme]="theme"
                class="bar-chart"
              ></div>
            </div>
          </div>
          <angular2-multiselect
          [data]="popularLimitData"
          [settings]="popularBooksSettings"
          [(ngModel)]="popularLimit"
          (onSelect)="OnPopularSelect($event)"
          (onDeSelect)="OnPopularDeSelect($event)"
        >
        </angular2-multiselect>
          <div
            echarts
            [options]="popularBooksOptions"
            [theme]="theme"
            class="bar-chart"
          ></div>


      </section>
      <section>
        <span class="section-title">Tartalom megtekintés</span>
        <div class="m-1">
          <angular2-multiselect
            [data]="bookChartData"
            [settings]="fileViewSettings"
            [(ngModel)]="selectedBook"
            (onSelect)="OnBookSelect($event)"
            (onDeSelect)="OnBookDeSelect($event)"
          >
          </angular2-multiselect>
        </div>
        <div
        echarts
        [options]="fileViewOptions"
        [theme]="theme"
        class="bar-chart"
      ></div>
      </section>
    </div>
  </div>
</div>
